<template>
  <div>
    <CCard>
        <CCardHeader>
        <CIcon name="cil-book"/> <span  v-if="title == '/my_uploads'">My Uploaded</span>
        <span  v-else>Study</span>
        Contents
        <div class="card-header-actions">
            <small class="text-muted">
                <CButton color="primary" @click="add_contents" v-if="title == '/my_uploads'">Add + </CButton>
                <CButton color="primary" @click="add_contents" v-if="user_role_id == '8de886dd-12e5-4b02-b812-187639513779' || 
                user_role_id == '6b82d8f3-e879-42de-a8ff-2426b61c9ab9' 
                ">Add + </CButton>
            </small>
        </div>
        </CCardHeader>
        <CCardBody>
            <CCard>
                <CCardHeader>
                    <CIcon name="cil-justify-center"/>
                    <strong> Tutorial - 1 </strong>
                    <div class="card-header-actions">
                    <a 
                        href="https://coreui.io/vue/docs/components/jumbotron" 
                        class="card-header-action" 
                        rel="noreferrer noopener" 
                        target="_blank"
                    >
                        <small class="text-muted">PDF</small>
                    </a>
                    </div>
                </CCardHeader>
                <CCardBody>
                    <CJumbotron style="padding:20px; padding-left:30px;">
                    <h4>Maths - Chapter - 1</h4>
                    <p class="lead">Language - English</p>
                    <p>Class - 5</p>
                    <CButton  size = "sm" color="primary" variant="outline"><CIcon name="cil-file"/></CButton>&nbsp;
                    <CButton  size = "sm" color="info" variant="outline"><CIcon name="cil-cloud-download"/></CButton>
                    </CJumbotron>
                </CCardBody>
            </CCard>
            <CCard>
                <CCardHeader>
                    <CIcon name="cil-justify-center"/>
                    <strong> Tutorial - 2 </strong>
                    <div class="card-header-actions">
                    <a 
                        href="https://coreui.io/vue/docs/components/jumbotron" 
                        class="card-header-action" 
                        rel="noreferrer noopener" 
                        target="_blank"
                    >
                        <small class="text-muted">PDF</small>
                    </a>
                    </div>
                </CCardHeader>
                <CCardBody>
                    <CJumbotron style="padding:20px; padding-left:30px;">
                    <h4>Maths - Chapter - 2</h4>
                    <p class="lead">Language - English</p>
                    <p>Class - 5</p>
                    <CButton  size = "sm" color="primary" variant="outline"><CIcon name="cil-file"/></CButton>&nbsp;
                    <CButton  size = "sm" color="info" variant="outline"><CIcon name="cil-cloud-download"/></CButton>
                    </CJumbotron>
                </CCardBody>
            </CCard>
            <!-- <CRow style="background-color:white;padding:5px;border-bottom:1px solid gray;" >
                <CCol md="1">
                    
                </CCol>
                <CCol md="2">
                    <CIcon name="cil-book"/>
                </CCol>
                <CCol md="7">
                <table>
                    <tr>
                        <th>Content Name</th><td>Maths Tutorial -1</td>
                    </tr>
                    <tr>
                        <th>Language - Class</th><td>English - 5</td>
                    </tr>
                    <tr>
                        <th style="font-size:12px;">Size - Uploaded On</th><td style="font-size:12px;">10MB - 30/06/2021</td>
                    </tr>
                </table>
                </CCol>
                <CCol md="2">
                    <CButton  size = "sm" color="primary" variant="outline"><CIcon name="cil-file"/></CButton>&nbsp;
                    <CButton  size = "sm" color="info" variant="outline"><CIcon name="cil-cloud-download"/></CButton>
                </CCol>
            </CRow> -->
        </CCardBody>
        <CRow style="margin-bottom:5px;">
            <CCol md="10"></CCol>
            <CCol md="2">
                <CButton size="sm"  color="primary">View More</CButton>
            </CCol>
        </CRow>
    </CCard>
    
    
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "study_content",
  data(){
      return{
          content_data:[],
          title:"",
          user_role_id :""
      }
  },
  components: {
  },
  methods:{
      add_contents(){
          this.$router.push('/add_study_content');
      },
      get_content(){
          var data = {
              id: "D5C85475-FDEB-408B-B9D8-08D90FC5DD42"
          };
          axios.get('https://localhost:44331/api/Contents/D5C85475-FDEB-408B-B9D8-08D90FC5DD42').then(response => {
              console.log(response);
              this.content_data = response.data;
          }).catch(error => {
              console.log(error);
          })
      }
  },
  created(){
    let user_id = this.$session.get('user_id');
    this.user_role_id = this.$session.get('user_role_id');
    if(user_id == undefined || user_id == null)
    {
      return '/';
      //this.$router.push('/auth/login');
    } 
   // this.get_content();
    this.title = this.$route.fullPath;
  }
};
</script>
